<template>
  <v-app>

    <v-app-bar  dark :clipped-left="$vuetify.breakpoint.lgAndUp" app :src="require('@/assets/lapt.jpg')">
      <template v-slot:img="{ props }">
        <v-img v-bind="props" gradient="to top right, rgba(49,49,69,.5), rgba(27,132,167,.5)"></v-img>
      </template>

      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>


      <v-app-bar-title> 
        
      
          <v-img
          style="width: 130px;"
          alt="School Logo"
          class="shrink"
          contain
          :src="logo_main"
          transition="scale-transition"
        />
      
    
      
      </v-app-bar-title>
      <v-app-bar-title> <span> <span v-if="grno_empcode!=''">{{grno_empcode}} : </span>{{name}}</span> </v-app-bar-title>
       
      <v-spacer />
 
      <speed class="mr-5"></speed>
      
    <router-link to="/analytics-dashboard" class="sidebar-link">
      <v-icon>
        mdi mdi-view-dashboard
      </v-icon>
    </router-link>

    <div v-if="notification_list.length > 0" class="text-center">
      <v-menu
        v-model="notificationmenu"
        :close-on-content-click="true"
       min-width="600"
        offset-x
        max-height="600"
        transition="scroll-y-transition"
      >
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">          
            <v-avatar>
              <v-icon >mdi-bell </v-icon>  <sup v-if="notification_list.length > 0" style="color:red;font-weight:900;font-size:15px" @click="getnotification(uid)">{{notification_list.length}}</sup>
            </v-avatar>   
          </v-btn>
        </template>

        <v-card max-width="600" class="mr-5" >
       <div v-for="(i, index) in notification_list" :key="index">
        
          <div v-if="i.isread ==true" style="background-color:#CFD8DC" >
            <v-card-text ><span v-html="i.message"></span>
            </v-card-text>
           <!-- <v-card-action style="cursor:pointer;margin-left:15px;" @click="getnotificationRead(i.id)">
              Read
            </v-card-action>-->
          </div>
          <div v-else>
            <v-card-text @click="getnotificationRead(i.id)">
            <span v-html="i.message"></span>
             <!-- {{i.message}} -->
            </v-card-text>
           <!--- <v-card-action style="cursor:pointer;margin-left:15px;" @click="getnotificationRead(i.id)">
              Read
            </v-card-action>-->
          </div>     
            <div id="divider" style="background-color:#263238; height:1px; width:100%;"></div>
            
        </div>
        
        </v-card>
      </v-menu>
    </div>
    <div style="width: 10px"></div>
    <div class="text-center">
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-width="200"
        offset-x
        transition="scroll-y-transition"
      >
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <span v-if="profile_image_path">
              <v-avatar>
                <v-img
                  :src="awspath + profile_image_path + profile_image_name"
                ></v-img>
              </v-avatar>
            </span>
            <span v-else>
              <v-avatar color="white">
                <span class="indigo--text headline">{{ initials }}</span>
              </v-avatar>
            </span>
          </v-btn>
        </template>

        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <span v-if="profile_image_path">
                  <v-avatar>
                    <v-img
                      :src="awspath + profile_image_path + profile_image_name"
                    ></v-img>
                  </v-avatar>
                </span>
                <span v-else>
                  <v-avatar color="white">
                    <span class="indigo--text headline">{{ initials }}</span>
                  </v-avatar>
                </span>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title
                  >Hello, {{ data.username }}</v-list-item-title
                >
                <!-- <v-list-item-subtitle>{{ uid }}</v-list-item-subtitle> -->
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list shaped>
            <v-list-item-group color="primary">
              
              <router-link v-if="usertype=='Student'" to="/student-profile" class="sidebar-link">
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="warning">mdi-account-circle-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>User Profile</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </router-link>
             

              <router-link to="/update-password" class="sidebar-link">
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="secondary">mdi-account-key</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Change Password</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </router-link>
              
              <v-list-item @click="bypasslogout();" v-if="isbypasslogout=='true'">

                  <v-list-item-icon>
                    <v-icon color="secondary">mdi-history</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Bypass Logout</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
            </v-list-item-group>
          </v-list>


          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="ma-1"
              outlined
              small
              fab
              color="error"
              @click="closeMenuAndLogout()"
              title="logout"
            >
              <v-icon>mdi-location-exit</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </div>

    </v-app-bar>
    <v-navigation-drawer  width="350" v-model="drawer"  :clipped="$vuetify.breakpoint.lgAndUp" app>
      <v-expansion-panels v-model="expan" focusable accordion>
        <v-expansion-panel class="pa-4" v-for="(child, i) in items" :key="i">
          <v-expansion-panel-header >
            {{ child.name }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div v-for="link in child.links" :key="link.linkname" @click="clear()">
              <router-link :to="{ name: link.link }" class="sidebar-link">
                <v-list-item link>
                  <v-list-item-icon>
                    <v-icon class="btn-title">{{ link.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ link.link_name }} </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </router-link>
              <v-divider></v-divider>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

    </v-navigation-drawer>
    <v-content>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-content>
  </v-app>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import speed from "@/plugins/Speed.vue";

import { watch } from "vue";
import axios from "axios";
export default {
  components: { speed },
  data() {
    return {
      drawer: true,
      group: null,
      items: [],
      initials: "",
    menu: false,
    notificationmenu : false,
      logo_main:
      "https://vierp-test.s3.ap-south-1.amazonaws.com/logo/eduplus_campus_white.png",
      profile_image_path:"",
      profile_image_name:"",
      notification_list:"",
      name:"",
      grno_empcode:"",
      uername:"",
      data: {},
      dialog :false,
    notification_list:[],
      expan:0,

      isbypasslogout:false,
    token:"",
    uid: "",
    tenant: "",
    usertype: "",
    bypassinstuid : "",
    awspath:"",
    usertype:"",
    };
  },
  watch: {
    group() {
      this.drawer = false
    },
  },

  mounted() {
    this.isbypasslogout = localStorage.getItem("Isbypass");
    this.bypassinstuid = localStorage.getItem("instuid");
    this.$store.commit("setquick_links", true);
    this.awspath = localStorage.getItem("awspath");
    this.uid = localStorage.getItem("EPS-uid");
    this.tenant = localStorage.getItem("EPS-tenant");
    this.init();
  },
  methods: {
    clear()
  {
  localStorage.removeItem("scheduling_object");
      localStorage.removeItem("companyname");
      localStorage.removeItem("scheduling_id");
  },
  bypasslogout() {
    this.token = localStorage.getItem("EPS-tokenbackup")
    this.uid = localStorage.getItem("EPS-uidbackup")
    this.tenant = localStorage.getItem("EPS-tenantbackup")
    this.usertype = localStorage.getItem("EPS-usertypebackup")

    localStorage.setItem("EPS-token", this.token);
    localStorage.setItem("EPS-uid", this.uid);
    localStorage.setItem("EPS-tenant", this.tenant);
    localStorage.setItem("EPS-usertype", this.usertype);

    localStorage.removeItem("EPS-tokenbackup");
    localStorage.removeItem("EPS-uidbackup");
    localStorage.removeItem("EPS-tenantbackup");
    localStorage.removeItem("EPS-usertypebackup");
    localStorage.removeItem("Isbypass");
    localStorage.removeItem("instuid");

   
    this.$router.push("/home")
    window.location.reload();
    
    },
    closeMenuAndLogout() {
      this.menu = false;
      if(sessionStorage.getItem("EPS-uid")!="sessionend"){
        sessionStorage.setItem("EPS-uid","sessionend");
        window.close();
        localStorage.clear();
        this.$router.push("/");
      }else{
        localStorage.clear();
        this.$router.push("/");
      }
    },
    getnotification(item){
      this.dialog = true
    },
    getnotificationRead(item){
     // alert("in getnotificationRead"+item);
      var params={
        'notification':item
      };
     
      axios
      .post("StudentApplicationTrack/getnotificationRead",params)
      .then((res) => {
        if (res.data.code == "200") {
          alert("notification Read ..!!")
        } else {
        }
      })
      .catch((error) => {
        window.console.log(error);
      });

    },
    back(){
      this.$router.push('/home');
    },
    imageReplacement(uername) {
      //var names = this.uername.split('');
      //console.log("names "+names)
      this.initials = uername[0].charAt(0).toUpperCase();
      // window.alert(initials)
    },
    init() {
      axios
        .get("/login/slidebardashboardnew")
        .then(res => {
          this.items = res.data.links;

          this.data = res.data;
          this.uername = this.data.username;
          this.grno_empcode = this.data.grno_empcode;
          this.name = this.data.name;
          this.notification_list = res.data.notification_list
          // window.alert(this.uername)
          this.profile_image_name = this.data.profile_image_name;
          this.profile_image_path = this.data.profile_image_path;
          this.usertype=res.data.usertype;
          this.imageReplacement(this.uername);
        })
        .catch(error => {
          window.console.log(error);
          this.$router.push("/");
        });
    }
  },


};
</script>
<style>
.sidebar-link {
  text-decoration: none;
}
.sidebar-link-item:hover {
  background-color: rgb(181, 186, 190) !important;
}
.btn-title{
        text-transform: uppercase;
      background: linear-gradient(to right, #30CFD0 0%, #330867 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      
    }
    .logi{
   
   background: linear-gradient(to top, #09203f 0%, #537895 100%);
}

::-webkit-scrollbar {
  width: 10px;
  height: 20px;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.10), inset 0 -1px 0 rgba(0, 0, 0, 0.07);
}
</style>