<template>
<span id="speed" >
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
        <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
        <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
            <span class="speed-text " v-bind="attrs" v-on="on"  >
              <span class="text-uppercase"> {{ speedInMbps }} </span>⚡️Mbps</span>
        </template>
        <span>Your Average Internet Speed</span>
    </v-tooltip>
  </span>
</template>

<script>


export default {  
  
    data: () => ({
        snackbar_msg: "",
            color: "",
            snackbar: false,
            startTime:"",
            endTime:"",
            speedInMbps:""
        
    }),
   
    mounted() {
      
                this.created(); 
    },
    updated() {
       
    },
    methods: {
        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },
        async created (){
            this.startTime = new Date().getTime();
             await this.getSpeed()
          
        },
       async getSpeed() {
        var  imageSize=0
            let imageLink = "https://easyplacements.s3.ap-south-1.amazonaws.com/speed_test/10mbsample.pdf";
             fetch(imageLink).then((response) => {
                     
                    imageSize = response.headers.get("content-length");
                    this.calculateSpeed(imageSize);
                });
               
        },
         calculateSpeed(imageSize) { 
            this.endTime = new Date().getTime();
            
           // The size in bytes 
                let timeDuration = (this.endTime - this.startTime) / 1000;
            let loadedBits = imageSize ;
            let speedInBps = (loadedBits / timeDuration).toFixed(2);
            let speedInKbps = (speedInBps / 1024).toFixed(2);
             var speedInMbps1 = (speedInKbps / 1024).toFixed(2);
                      console.log(timeDuration);
                      console.log(loadedBits);
                      console.log(speedInBps);
                      console.log(speedInKbps);
                      this.speedInMbps=(speedInMbps1/8).toFixed(2);
            } 
          
    },
}
</script>

<style scoped>
.speed-text {
    color:#ffffff; 
    font-size: 14px;
   
}
</style>
